import React, { Component } from "react";
import Media from "react-media";
import backgroundHome from "./../images/mundo.jpg";
import WelcomeWords from "../components/WelcomeWords";
import { withTranslation } from "react-i18next";

const container = {
  backgroundImage: "url(" + backgroundHome + ")",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundAttachment: "fixed",
  backgroundSize: "cover",
  height: "100%",
  width: "100%",
  position: "fixed",
  bottom: 0,
};

const textCenter = {
  textAlign: "center",
};

const textInside = {
  display: "inline-block",
  textAlign: "left",
};

const text = {
  textAlign: "left",
  color: "#111",
  padding: 10,
  backgroundColor: "rgb(175, 175, 175, 0.7)",
  position: "relative",
};

const textPc = {
  font: "30px Permanent Marker, cursive",
  marginLeft: "55%",
  marginTop: "175px",
  top: 185,
};

const textMobile = {
  font: "20px Permanent Marker, cursive",
  marginLeft: 30,
  top: 240,
};

const titlePc = {
  font: "200px Lacquer, sans-serif",
};

const titleMobile = {
  font: "110px Lacquer, sans-serif",
};

const titleAfterPc = {
  left: "10px",
};

const titleAfterMobile = {
  left: "6px",
};

class HomeScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  text = (textStyle, textStyleDevice) => {
    return (
      <div style={{ ...textStyle, ...textStyleDevice }}>
        {this.props.t("home.intro.name")}
        <br /> {this.props.t("home.intro.profession")}
      </div>
    );
  };

  componentDidMount() {
    document.title = "Marcos Bustamante Mateo";
  }

  render() {
    return (
      <div className="Home ScreenContainer" style={container}>
        {/*<Media query="(max-width: 599px)">
          {(matches) =>
            matches ? (
              <WelcomeWords
                titleStyle={titleMobile}
                titleAfterStyle={titleAfterMobile}
              />
            ) : (
              <WelcomeWords
                titleStyle={titlePc}
                titleAfterStyle={titleAfterPc}
              />
            )
          }
        </Media>*/}
        <div style={textCenter}>
          <div className="titleHome" style={textInside}>
            {this.props.t("home.welcome.part1").toUpperCase()}
            <br></br>
            {this.props.t("home.welcome.part2").toUpperCase()}
            <br></br>
            {this.props.t("home.welcome.part3").toUpperCase()}
          </div>
        </div>

        <Media query="(max-width: 799px)">
          {(matches) =>
            matches ? this.text(text, textMobile) : this.text(text, textPc)
          }
        </Media>
      </div>
    );
  }
}

export default withTranslation("common")(HomeScreen);
