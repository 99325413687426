import React, { Component } from "react";
import Fab from "@material-ui/core/Fab";
import EmailIcon from "@material-ui/icons/Textsms";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import { withTranslation } from 'react-i18next';
import GetAppIcon from "@material-ui/icons/GetApp";

const opacityTransition = {
  opacity: 1,
  transition: "opacity 2s"
};

const floatButton = {
  position: "fixed",
  left: 10,
  top: "auto",
  margin: "0",
  right: "auto",
  background: "linear-gradient(45deg, #FE6B8B 45%, #FF8E53 100%)",
  borderRadius: 30,
  opacity: 0
};

const floatButtonTwo = {
  position: "fixed",
  inset: "auto 10px 10px auto",
  left: "auto",
  top: "auto",
  margin: "0",
  right: 10,
  background: "linear-gradient(45deg, #FE6B8B 45%, #FF8E53 100%)",
  // Whatsapp colour
  // backgroundColor: "#25D366",
  borderRadius: 30,
  opacity: 0
};

const contactButton = {
  bottom: 10
};

class FloatButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tootlTipVisible: false,
      tootlTipVisibleTwo: false,
      mobile: false,
      open: false,
      contactButtonStyle: { ...floatButton, ...contactButton },
      contactButtonTwoStyle: { ...floatButtonTwo, ...contactButton },
      appInstalled: false,
      installPrompt: null
    };
  }

  handleToolTip = value => {
    this.setState({ tootlTipVisible: value });
  };

  handleToolTipTwo = value => {
    this.setState({ tootlTipVisibleTwo: value });
  };

  handleContact = () => {
    const { mobile } = this.state;
    window.location.href = `mailto:${this.props.t('email')}`;
  };

  handleContactWhatsapp = () => {
    window.location.href = this.props.t("enlace_whatsapp");
  };

  handleInstallPWA = () => {
    const { installPrompt } = this.state;
    if (installPrompt)
      installPrompt.prompt();
  }

  detectDeviceType = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
      ? true
      : false;
  };

  componentDidMount() {
    this.setState({
      mobile: this.detectDeviceType(),
      appInstalled: true
    });

    setInterval(() => {
      this.setState({
        contactButtonStyle: {
          ...this.state.contactButtonStyle,
          ...opacityTransition
        },
        contactButtonTwoStyle: {
          ...this.state.contactButtonTwoStyle,
          ...opacityTransition
        }
      });
    }, 0);

    // PWA Install Button
    const installButton = document.querySelector("#install");
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      this.setState({ installPrompt: event, appInstalled: false });
    });

    // PWA Already installed 
    window.addEventListener("appinstalled", () => {
      // disableInAppInstallPrompt();
      this.setState({ appInstalled: true });
    });
  }

  
  render() {
    const {
      tootlTipVisible,
      tootlTipVisibleTwo,
      mobile,
      contactButtonStyle,
      contactButtonTwoStyle,
      appInstalled
    } = this.state;
    
    const whatsappImage = require("./../images/whatsapp.png");

    return (
      <div>
        {!appInstalled
        ? 
          <Tooltip
          title={this.props.t("tooltip.download")}
          placement="left"
          TransitionComponent={Zoom}
          open={tootlTipVisible}
          >
            <Fab
              onMouseEnter={() => this.handleToolTip(true)}
              onMouseLeave={() => this.handleToolTip(false)}
              onClick={() => {
                this.handleToolTip(false);
                this.handleInstallPWA();
              }}
              style={contactButtonTwoStyle}
            >
              <GetAppIcon />
            </Fab>
          </Tooltip>
        : null }

      {/*
        <Tooltip
          title={this.props.t("tooltip.text")}
          placement="left"
          TransitionComponent={Zoom}
          open={tootlTipVisible}
        >
          <Fab
            onMouseEnter={() => this.handleToolTip(true)}
            onMouseLeave={() => this.handleToolTip(false)}
            onClick={() => {
              this.handleToolTip(false);
              this.handleContact();
            }}
            style={contactButtonStyle}
          >
            <EmailIcon />
          </Fab>
        </Tooltip>


        <Tooltip
          title={mobile ? this.props.t("tooltip.call") : this.props.t("tooltip.text")}
          placement="left"
          TransitionComponent={Zoom}
          open={tootlTipVisibleTwo}          
        >
          <Fab
            onMouseEnter={() => this.handleToolTipTwo(true)}
            onMouseLeave={() => this.handleToolTipTwo(false)}
            onClick={() => {
              this.handleToolTipTwo(false);
              this.handleContactWhatsapp();
            }}
            style={contactButtonTwoStyle}
            id="install"
          >            
            <img src={whatsappImage} alt="logo of Whatsapp" style={{ width: '100%', borderRadius: '50%' }} />
          </Fab>
        </Tooltip>
      */}
      </div>
    );
  }
}

export default withTranslation('common')(FloatButtons);
