import React, { Component } from "react";
import { withTranslation } from "react-i18next";

const styleHover = {
  transition: "transform .3s",
  transform: "scale(1.1)"
}

class ContactIcon extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        hover: false
      };
    }
  
    handleHover = () => {
      this.setState({ hover: !this.state.hover });
    };
  
    render() {
        const { hover } = this.state;
        const { title, href, svg, colorIcon, backgroundColor, paperStyle } = this.props;
  
        return (
            <a href={href} target="_blank" rel="noopener noreferrer">
              <svg viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg" width={100} 
                style={ hover ? styleHover : null}
                onMouseEnter={this.handleHover}
                onMouseLeave={this.handleHover}>
                  <title>
                      {title}
                  </title>
                  <g fill="none" fillRule="evenodd">
                      <circle fill={backgroundColor} cx="300" cy="300"r="252.5" />
                      <path d={svg} fill={colorIcon} fill-rile="nonzero" />
                  </g>
              </svg>
            </a>
        );
    }
  }
  
  export default withTranslation("common")(ContactIcon);
