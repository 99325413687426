import i18next from "i18next";
import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

const pdfStyle = {
  marginTop: 80,
  position: "fixed"
};

const textStyle = {
  marginTop: 75,
  textAlign: "center"
};

const linkStyle = {
  textDecoration: "none"
};

const imageStyle = {
  width: 100
};

class CvScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {

    const pdf = i18next.language == 'es' ? require(("./../CV-2023.pdf")) : require(("./../CV-2023-EN.pdf"));

    return (
      <div>

        <iframe
          title="PDF"
          src={`/pdfjs-3.8.162-dist/web/viewer.html?file=${pdf}`}
          width="100%"
          height="100%"
          style={pdfStyle}
        ></iframe>



      </div>
    );
  }
}

export default withTranslation('common')(CvScreen);
